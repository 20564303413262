export async function loginWithGoogle() {
  const supabase = useSupabaseClient()

  let { protocol, host } = useRequestURL()
  if (protocol !== 'https:') protocol = 'http:'
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      scopes: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/drive.file',
      redirectTo: `${protocol}//${host}/auth/callback`,
      queryParams: {
        access_type: 'offline',
        prompt: 'consent',
      },
    },
  })
  error ? console.error(error) : console.log(data.url)
}

export async function logout() {
  const supabase = useSupabaseClient()
  await supabase.auth.signOut()
  await navigateTo('/')
}
